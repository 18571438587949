"use client";
import { ApolloProvider } from "@apollo/client";
import createClient from "@/apollo/client";
import {
  ANONYMOUS_UUID_COOKIE_NAME,
  RESTAURANT_ID_COOKIE_NAME,
  TOKEN_COOKIE_NAME,
} from "@/apollo/utils";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { FC, PropsWithChildren, useMemo } from "react";
import { getCookie } from "cookies-next/client";
import Scroll from "@/components/Scroll";
import { theme } from "@/theme";
import AnalyticsProvider from "@/app/AnalyticsProvider";

export const Wrappers: FC<PropsWithChildren> = ({ children }) => {
  const client = useMemo(() => {
    return createClient(() => ({
      authToken: getCookie(TOKEN_COOKIE_NAME),
      restaurantID: getCookie(RESTAURANT_ID_COOKIE_NAME),
      anonymousUUID: getCookie(ANONYMOUS_UUID_COOKIE_NAME),
    }));
  }, []);

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        {/* NOTE: https://github.com/vercel/next.js/issues/45187 */}
        <Scroll />
        <AnalyticsProvider>{children}</AnalyticsProvider>
      </ChakraProvider>
    </ApolloProvider>
  );
};
