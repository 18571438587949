"use client";
import { ApolloClient, from, InMemoryCache } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import fragmentMatcherCodegen from "@/documents/__generated__/fragmentMatcher.codegen";
import { AbortError } from "@/utils/abortError";
import { getAuthLink, isServerParseError, link } from "@/apollo/utils";

const createClient = (
  getSession: () => {
    authToken?: string;
    restaurantID?: string;
    anonymousUUID?: string;
  } = () => ({}),
) => {
  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      for (const { message, locations, path } of graphQLErrors) {
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
          )}, Path: ${path}, Operation: ${
            operation.operationName
          }, Variables: ${JSON.stringify(operation.variables)}`,
        );
      }
    }
    if (networkError) {
      if (networkError instanceof AbortError && networkError.code === 0) {
        return;
      }
      if (isServerParseError(networkError) && networkError.statusCode === 401) {
        return;
      }
      console.error(
        `[Network error]: Message: ${networkError.message}, Cause: ${networkError.cause}, Operation: ${operation.operationName}, Variables: ${JSON.stringify(operation.variables)}`,
      );
    }
  });

  return new ApolloClient({
    cache: new InMemoryCache({
      possibleTypes: fragmentMatcherCodegen.possibleTypes,
      typePolicies: {
        Shipment: {
          keyFields: ["id", "uniqueId"],
        },
        DeliveryTime: {
          // NOTE: This is a workaround for the fact that the DeliveryTime type is not unique
          keyFields: ["id", "date", "price"],
        },
      },
    }),
    devtools: {
      enabled: process.env.NODE_ENV === "development",
    },
    link: from([errorLink, getAuthLink(getSession), link]),
  });
};

export default createClient;
