import { HttpLink, ServerParseError } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { NetworkError } from "@apollo/client/errors";

export const TOKEN_COOKIE_NAME = "auth_token";
export const INVITATION_COOKIE_NAME = "invitation_token";
export const RESTAURANT_ID_COOKIE_NAME = "current_restaurant_id";
export const AUTH_TOKEN_HEADER = "X-AUTH-TOKEN";
export const RESTAURANT_ID_HEADER = "X-RESTAURANT-ID";
export const ANONYMOUS_UUID_COOKIE_NAME = "anonymous_uuid";
export const ANONYMOUS_UUID_HEADER = "ANONYMOUS-UUID";
export const DEVICE_ID_COOKIE_NAME = "device_id";

export const link = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_BACKEND_BASE_URL}/graphql`,
});

export const isServerParseError = (
  error: NetworkError,
): error is ServerParseError => {
  return !!error && Object.hasOwn(error, "statusCode");
};

export const getAuthLink = (
  getSession: () => {
    authToken?: string;
    restaurantID?: string;
    anonymousUUID?: string;
    allowIncomplete?: boolean;
  },
) => {
  return setContext((operation, { headers = {}, ...rest }) => {
    const { authToken, restaurantID, allowIncomplete, anonymousUUID } =
      getSession();
    return {
      headers: {
        ...headers,
        "Content-Type": "application/json",
        "PUBLIC-API-TOKEN": String(process.env.NEXT_PUBLIC_API_TOKEN),
        "CLIENT-VERSION": String(process.env.NEXT_PUBLIC_CLIENT_VERSION),
        ...(authToken && restaurantID
          ? {
              [AUTH_TOKEN_HEADER]: authToken,
              [RESTAURANT_ID_HEADER]: restaurantID,
            }
          : (allowIncomplete || operation.operationName === "AppSettings") &&
              authToken
            ? {
                [AUTH_TOKEN_HEADER]: authToken,
              }
            : {
                [ANONYMOUS_UUID_HEADER]: anonymousUUID,
              }),
      },
      ...rest,
    };
  });
};
